import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				STARLIGHT REAL ESTATE
			</title>
			<meta name={"description"} content={"Ласкаво просимо до Starlight Real Estate, де ваші мрії про ідеальну оселю втілюються в реальність"} />
			<meta property={"og:title"} content={"STARLIGHT REAL ESTATE"} />
			<meta property={"og:description"} content={"Ласкаво просимо до Starlight Real Estate, де ваші мрії про ідеальну оселю втілюються в реальність"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/662a2c2a6bbd2000236a0bc4/images/2-3.jpg?v=2024-04-26T14:30:50.829Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/662a2c2a6bbd2000236a0bc4/images/2-3.jpg?v=2024-04-26T14:30:50.829Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/662a2c2a6bbd2000236a0bc4/images/2-3.jpg?v=2024-04-26T14:30:50.829Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/662a2c2a6bbd2000236a0bc4/images/2-3.jpg?v=2024-04-26T14:30:50.829Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/662a2c2a6bbd2000236a0bc4/images/2-3.jpg?v=2024-04-26T14:30:50.829Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/662a2c2a6bbd2000236a0bc4/images/2-3.jpg?v=2024-04-26T14:30:50.829Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/662a2c2a6bbd2000236a0bc4/images/2-3.jpg?v=2024-04-26T14:30:50.829Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661cef388e2e8e00217acf89"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});